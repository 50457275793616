<script lang="ts">
import {
	defineComponent,
	onMounted,
	onUnmounted,
	ref,
	type PropType,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import ApocImageSet from "@/components/common/ApocImageSet.vue";
import { initStore } from "@/stores/store-manager";
import { POPUP_MODE } from "@/stores/state-store";

export default defineComponent({
	name: "PaymentSuccessPopup",
	components: {
		ApocImageSet,
	},
	setup() {
		const { t } = useI18n();
		const router = useRouter();
		const storeManager = initStore();
		const isSuccess = ref<boolean>(false);

		const handlePopupClose = (mode: POPUP_MODE) => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
			router.replace("/");
		};

		const onClickConfirm = () => {
			handlePopupClose(POPUP_MODE.NONE);
		};
		onMounted(() => {
			isSuccess.value = storeManager.dataStore.isPaymentSuccess;
		});
		onUnmounted(() => {
			storeManager.dataStore.setIsPaymentSuccess(false);
		});

		return {
			t,
			onClickConfirm,
			isSuccess,
		};
	},
});
</script>

<template>
	<div class="payment-success-popup">
		<div class="content-wrapper">
			<div class="img-section">
				<apoc-image-set src="/assets/images/popup/payment-success-icon.webp" :img-sets="3" />
			</div>
			<div class="text-section">
				<div class="title">{{ isSuccess ? t('popup.paymentSuccessPopup.title') : t("msg.FAILED_PAYMENT_MODULE_SYSTEM_PROCESSING") }}</div>
				<!-- <div class="desc">{{ t('popup.paymentSuccessPopup.desc') }}</div> -->
			</div>
			<div class="button-section" @click="onClickConfirm">{{ t('popup.paymentSuccessPopup.confirm') }}</div>
		</div>
	</div>
</template>
<style scoped>
/** 폰트 적용 */
:deep(*) {
	font-family: Famppy, sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
