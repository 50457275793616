import { defineStore } from 'pinia';
import { ref } from 'vue';

export enum HEADER_TYPE {
	DEFAULT = 'DEFAULT',
	BACK_TITLE = 'BACK_TITLE',
	BACK_MENU = 'BACK_MENU',
	BACK_OPTION = 'BACK_OPTION',
}

//  * 팝업 모드
export enum POPUP_MODE {
	NONE = 'NONE',
	CONTENTS_OPTION_MODAL = 'CONTENTS_OPTION_MODAL',
	CONTENTS_SELECT_MODAL = 'CONTENTS_SELECT_MODAL',
	LANG_SELECT_MODAL = 'LANG_SELECT_MODAL',
	COPYRIGHT_POPUP = 'COPYRIGHT_POPUP',
	COPY_TOAST_POPUP = 'COPY_TOAST_POPUP',
	CHANNEL_MODAL = 'CHANNEL_MODAL',
	MOBILE_MENU_POPUP = 'MOBILE_MENU_POPUP',
	POPUP_INFO_CHANNEL = 'POPUP_INFO_CHANNEL',
	REPORT_POPUP = 'REPORT_POPUP',
	MAKE_POPUP = 'MAKE_POPUP',
	CONTENTS_COPY = 'CONTENTS_COPY',
	CONTENTS_RENAME = 'CONTENTS_RENAME',
	INSPECTION_MODAL = 'INSPECTION_MODAL',
	CONTENTS_POPUP = 'CONTENTS_POPUP',
	FOLLOW_MODAL = 'FOLLOW_MODAL',
	CONTENT_REPORT_POPUP = 'CONTENT_REPORT_POPUP',
	CONTENT_MORE_POPUP = 'CONTENT_MORE_POPUP',
	CONTENT_SHARE_POPUP = 'CONTENT_SHARE_POPUP',
	APOC_POPUPS = 'APOC_POPUPS',
	TABLET_SIDE_MENU = 'TABLET_SIDE_MENU',
	PAYMENT_SUCCESS_POPUP = 'PAYMENT_SUCCESS_POPUP'
}

export enum StoreMutType {
	setAuthToken = 'setAuthToken',
	loginUserUuid = 'loginUserUuid',
}

export const useStateStore = defineStore('state', () => {
	const replyCancel = ref<boolean>(false);
	const headerVisible = ref<boolean>(false);
	const contentsBigMode = ref<boolean>(false); // 콘텐츠 전체화면 상태의 유무에 대한 값
	const directContents = ref<boolean>(false); // 콘텐츠를 다이렉트 링크로 접근 했는지 유무에 대한 상태 값
	const contentsReply = ref<boolean>(false); // 콘텐츠 댓글바가 활성화 되었는지 감지하기 위한 상태 값
	const isDoSearchComment = ref<boolean>(false); // 댓글 삭제를 감지하여 창을 초기화 하기 위한 상태 값
	const headerState = ref<HEADER_TYPE>(HEADER_TYPE.DEFAULT); // 헤더에 대한 타입(ex. 버튼만, 버튼+제목 등)
	const popupMode = ref<POPUP_MODE>(POPUP_MODE.NONE); // 팝업 관리를 위한 상태 값
	const saveTabState = ref<boolean>(false);
	const directGoToAuthor = ref<boolean>(false); // 콘텐츠 관리에서 썸네일로 바로 수정할 수 있게 끔 해주는 상태 값
	const channelInfoEdited = ref<boolean>(false); // 채널 정보 수정에 대한 상태 값
	const popupCopyState = ref<boolean>(false); // 콘텐츠 공유 시 복사의 완료 유무를 감지하기 위한 상태 값
	const openAuthor = ref<boolean>(false); // 저작도구가 열려 있는지 꺼져 있는지를 체크하기 위한 상태 값
	const directReply = ref<boolean>(false); // 내 콘텐츠 관리에서 댓글 클릭해서 콘텐츠 상세 페이지로 들어온 경우인지 체크하기 위한 상태 값
	const contentsMorePlus = ref<boolean>(false);
	const loadingState = ref<boolean>(false); // 특정 시간동안 API가 완료되지 않았을 때 로딩바가 열리는 상태 값
	const isActiveSubscription = ref<boolean>(false); // 구독제 활성화
	const isOpenSearchBar = ref<boolean>(false);

	function setIsOpenSearchBar(v: boolean) {
		isOpenSearchBar.value = v;
	}

	function setContentsMorePlus(v: boolean) {
		contentsMorePlus.value = v;
	}

	function setHeaderVisible(v: boolean) {
		headerVisible.value = v;
	}

	function setReplyCancel(v: boolean) {
		replyCancel.value = v;
	}

	function setContentsBigMode(v: boolean) {
		contentsBigMode.value = v;
	}
	function setDirectContents(v: boolean) {
		directContents.value = v;
	}
	function setContentsReply(v: boolean) {
		contentsReply.value = v;
	}
	function setHeaderState(v: HEADER_TYPE) {
		headerState.value = v;
	}
	function setPopupMode(v: POPUP_MODE) {
		popupMode.value = v;
	}
	function setSaveTabState(v: boolean) {
		saveTabState.value = v;
	}
	function setDirectGoToAuthor(v: boolean) {
		directGoToAuthor.value = v;
	}
	function setChannelInfoEdited(v: boolean) {
		channelInfoEdited.value = v;
	}

	function setPopupCopyState(v: boolean) {
		popupCopyState.value = v;
	}
	function setOpenAuthor(v: boolean) {
		openAuthor.value = v;
	}
	function setIsDoSearchComment(v: boolean) {
		isDoSearchComment.value = v;
	}
	function setDirectReply(v: boolean) {
		directReply.value = v;
	}
	function setLoadingState(v: boolean) {
		loadingState.value = v;
	}

	return {
		contentsBigMode,
		directContents,
		contentsReply,
		isDoSearchComment,
		saveTabState,
		directGoToAuthor,
		channelInfoEdited,
		popupCopyState,
		openAuthor,
		popupMode,
		headerState,
		headerVisible,
		directReply,
		replyCancel,
		contentsMorePlus,
		loadingState,
		isActiveSubscription,
		isOpenSearchBar,
		setHeaderVisible,
		setIsOpenSearchBar,
		setReplyCancel,
		setHeaderState,
		setPopupMode,
		setOpenAuthor,
		setPopupCopyState,
		setChannelInfoEdited,
		setDirectGoToAuthor,
		setSaveTabState,
		setContentsReply,
		setDirectContents,
		setContentsBigMode,
		setIsDoSearchComment,
		setDirectReply,
		setContentsMorePlus,
		setLoadingState,
	};
});
